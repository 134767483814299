.navbar {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 5px 40px;
  gap: 40px;
  background-color: white;
}

.logo {
  max-width: 110px;
  margin-right: 30px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 5px 20px;
    gap: 20px;
  }
  .logo {
    max-width: 60px;
    margin-right: 0px;
  }
}
