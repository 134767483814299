/* flex */
.flex-1 {
  flex: 1;
}

.row-center {
  display: flex;
  align-items: center;
}

/* Seperator */

.vertical-seperator {
  display: block;
  width: 1px;
  height: 100%;
  border-right: 1px solid #747474;
}

.horizontal-seperator {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #747474;
}

/* hide */
.sm-hide {
  display: block;
}

@media screen and (max-width: 767px) {
  .sm-hide {
    display: none;
  }
}
